<script>
import axios from 'axios';
import {
  closeLoading,
  showLoading,
  showMessage,
  showError,
} from '@/components/widgets/swalUtils';

import { authMethods } from '@/state/helpers';
import appConfig from '@/app.config.json';

import { required, helpers } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import Layout from '../../layouts/auth.vue';

/**
 * Forgot Password component
 */
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  validations: {
    user_name: {
      required: helpers.withMessage('Email or Username is required', required),
    },
  },
  page: {
    title: 'Forgot Password',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
  },
  data() {
    return {
      user_name: '',
      submitted: false,
      error: null,
      tryingToReset: false,
      isResetError: false,
    };
  },
  methods: {
    ...authMethods,
    // Try to register the user in with the email, fullname
    // and password they provided.
    async tryToReset() {
      this.submitted = true;
      // stop here if form is invalid
      this.v$.$touch();

      if (this.v$.$invalid) {
        await showError('We found errors in the form.');
      } else {
        await showLoading('Looking for username...');
        await axios.post('auth/forgot-password', {
          user_name: this.user_name,
        }).then((res) => {
          if (res.data.error) {
            this.isResetError = true;
            this.error = res.data.error;
            closeLoading();
            return;
          }
          this.error = null;
          showMessage({
            title: 'Reset link sent!',
            text: res.data.success,
          });
        });
      }
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="bg-soft bg-primary">
            <div class="row">
              <div class="col-7">
                <div class="text-primary p-4">
                  <h5 class="text-primary">Reset Password</h5>
                  <p>Reset Password for <br>Command & Planning 4.</p>
                </div>
              </div>
              <div class="col-5 align-self-end pb-4">
                <img
                  alt="Logo"
                  class="img-fluid"
                  src="@/assets/images/logo.png"
                />
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div>
              <router-link to="/">
                <div class="avatar-md profile-user-wid mb-4">
                  <span class="avatar-title rounded-circle bg-light">
                    <img alt="Logo" height="34" src="@/assets/images/logo.svg"/>
                  </span>
                </div>
              </router-link>
            </div>

            <div class="p-2">
              <b-alert
                v-model="isResetError"
                class="mb-4"
                dismissible
                variant="danger"
              >{{ error }}
              </b-alert
              >
              <form @submit.prevent="tryToReset">
                <div class="mb-3">
                  <label for="user_name">Username or Email
                    <input
                      id="user_name"
                      v-model="user_name"
                      :class="{ 'is-invalid': submitted && v$.user_name.$errors }"
                      class="form-control"
                      placeholder="Enter username or email"
                      type="text"
                    />
                  </label>
                  <div
                    v-for="(item, index) in v$.user_name.$errors"
                    :key="index"
                    class="invalid-feedback"
                  >
                    <span v-if="item.$message">{{ item.$message }}</span>
                  </div>
                </div>
                <div class="mb-3 row mb-0">
                  <div class="col-12 text-end">
                    <button class="btn btn-primary w-md" type="submit">
                      Reset
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="mt-5 text-center">
          <p>
            Remember It ?
            <router-link class="fw-medium text-primary" to="/login"
            >Sign In here
            </router-link
            >
          </p>
          <p>
            © {{ new Date().getFullYear() }} Omnigo Software
          </p>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<style lang="scss" module></style>
